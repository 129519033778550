<template>
  <div
    :class="{ 'is-loading' : isLoading }"
    class="page blog-page">
      <spinner-elem></spinner-elem>
      <div class="row is-multiline">
          <div
            v-for="(article, i) in articles"
            :key="i"
            class="col is-6 is-12-sm article typo">
              <link-elem :link="article.head">
                <div class="image is-hidden-xs">
                  <image-elem :file="article.content.pageimage.value" size="blog.list" />
                </div>
                <h2>{{ article.content.title.value }}</h2>
                <text-elem :text="abstract(article)"></text-elem>
              </link-elem>
          </div>
      </div>
      <div
        v-if="pagecount > 1"
        class="pagination">
          <button-elem
            v-if="hasLess"
            @click="onClickPage(1)"
            class="button is-outline first">
              &lt;&lt;
          </button-elem>
          <div
            v-if="hasLess"
            class="placeholder">
              . . .
          </div>
          <button-elem
            v-for="i in paginator"
            :key="i"
            @click="onClickPage(i)"
            :class="{
              'is-outline': i !== current,
              'is-primary': i === current,
            }"
            class="button">
              {{ i }}
          </button-elem>
          <div
            v-if="hasMore"
            class="placeholder">
              . . .
          </div>
          <button-elem
            v-if="hasMore"
            @click="onClickPage(pagecount)"
            class="button is-outline last">
              &gt;&gt;
          </button-elem>
      </div>
  </div>
</template>

<script>
import API from '@/services/API'
import Scroll from '@/services/Scroll'

export default {
  name: 'blog-page',
  components: {},
  data () {
    return {
      articles: [],
      isLoading: true,
      paginator: [],
      page: 1,
      pagecount: 1,
      current: 1,
      hasLess: true,
      hasMore: true
    }
  },
  created () {
    this.getArticles()
  },
  methods: {
    abstract (article) {
      var abstract = article.content.abstract.value[0].html
      var author = article.content.author.value || 'Redaktion'
      return {
        value: [{
          tag: 'p',
          html: abstract + ' Von <span class="author">' + author + '.</span>'
        }],
        type: 'html'
      }
    },
    onClickPage(i) {
      if (this.current !== i) {
        this.current = i
        this.getArticles()
      }
    },
    calculatePaginator (head) {
      var i, start, end
      this.page = head.page
      this.pagecount = head.pagecount
      if (this.pagecount <= 6) {
        start = 1
        end = 6
      } else if (this.page <= 3) {
        start = 1
        end = start + 4
      } else if (this.page >= this.pagecount - 2) {
        start = this.pagecount - 4
        end = this.pagecount
      } else {
        start = this.page - 2
        end = start + 4
      }
      if (start < 1) {
        start = 1
      }
      if (end > this.pagecount) {
        end = this.pagecount
      }
      this.current = this.page
      this.paginator = []
      for(i = start; i <= end; i++) {
        this.paginator.push(i)
      }
      this.hasLess = start > 1
      this.hasMore = end < this.pagecount
    },
    getArticles () {
      var request = {
        order: 'desc',
        fields: ['title', 'abstract', 'pageimage', 'author'],
        limit: 10,
        page: this.current
      }
      this.isLoading = true
      API.children(this.$route.path, request)
        .then((response) => {
          this.articles = response.data.children
          this.calculatePaginator(response.data.head)
          this.isLoading = false
          this.$nextTick(() => {
            Scroll.smooth(0)
          })
        })
        .catch((reason) => {
          this.isLoading = false
        })
    }
  }
}
</script>

<style lang="sass">
.blog-page
  .article
    margin-bottom: m(3)
    transition: opacity 1s ease
    .image
      margin-bottom: m(2)
    .author
      +font('bold')
    h2
      margin-bottom: m(1)
  .pagination
    display: flex
    align-items: baseline
    justify-content: center
    .placeholder
      margin: 0 3px
      +font("bold")
    .button-elem
      margin: 0 3px          
  .spinner-elem
    display: none
    top: m(3)
    left: 50%
    transform: translateX(-50%)
  &.is-loading
    .spinner-elem
      display: block
</style>


